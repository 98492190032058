import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyBadge = lazy(
  () => import("./badge-BXWLAX3a.js").then((module) => ({
    default: module.Badge
  }))
);
function Badge({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyBadge, { ...props });
}
const useBadgeContent = () => {
  return useMemo(
    () => ({
      badge: {
        component: Badge
      }
    }),
    []
  );
};
export {
  LazyBadge as Badge,
  useBadgeContent
};
